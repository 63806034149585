@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@import url("https://cloud.typography.com/6614852/7403832/css/fonts.css");
@import url("https://use.typekit.net/llb7eqt.css");
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');

@layer components {

  .knockout, .menu > a, .knockout-32, .knockout-16, .knockout-24, .knockout-16  {
    font-family: "Knockout 48 A","Knockout 48 B","Arial Narrow",Arial,sans-serif;
    text-transform: uppercase;
    @apply tracking-wider;
    /* font-style: normal;
    font-weight: 200;
    letter-spacing: 1px; */
  }
  .knockout-32 {
    font-size: 32px;
  }
  .knockout-24 {
    font-size: 24px;
  }
  .knockout-16 {
    font-size: 16px;
  }

}
@layer base {
  .prose h1,
  .prose h2,
  .prose h3 { @apply knockout !important;}
  .prose h6 { @apply uppercase !important;}
  .prose input:not(.checkbox,.radio) { @apply w-2/4 !important; }
  .prose textarea { @apply w-3/4; }
  .prose dl { @apply flex-col gap-2;}
  .prose dd { @apply pl-8 ;}
  /* .prose blockquote {
      @apply py-4 border-l-accent-500 !important;
  }
  .prose blockquote p {
      @apply text-4xl text-secondary my-0;
  }
  .prose * { @apply py-0 my-0;}

  .prose h1,
  .prose h2,
  .prose h3 { @apply knockout text-primary tracking-wider font-thin !important; }
  .prose h1 {
      @apply text-4xl !important;
  }
  .prose h2 {
      @apply text-5xl !important;
  }
  .prose h3 {
      @apply text-4xl text-darkgray-500 !important;
  }
  .prose h4 {
      @apply text-3xl text-darkgray-500 !important;
  }
  .prose h5, .prose legend {
      @apply text-xl text-black !important;
  }
  .prose h6 {
      @apply text-sm uppercase font-bold text-black tracking-widest !important;
  }
  .prose p,
  .prose dd {
      @apply text-base;
  }
  .prose p.big {
      @apply text-lg;
  }
  .prose dd {
      @apply pl-8;
  }
  .prose dt {
      @apply font-bold;
  }
  .prose dl {
      @apply flex flex-col gap-2 pb-4;
  }
  */




  ::selection {
    @apply bg-warning-200 text-primary-900;
  }
  .prose form { @apply flex flex-col gap-y-4; }
  .prose fieldset > div { @apply text-base bg-lightgray-400 p-8 rounded-b-lg; }
  .prose fieldset div > div { @apply flex flex-row ; }
  .prose legend { @apply text-lg font-black bg-midgray-200 border-b border-midgray-400 text-black m-0 px-8 py-4 w-full rounded-t-lg !important; }
  .prose fieldset label { @apply w-1/5; }
  .prose fieldset label.checkbox-wrapper { @apply border-0 w-auto; }
  .prose fieldset .form-fields { @apply flex flex-col gap-y-2; }
  .prose input[type="text"],
  .prose input[type="email"],
  .prose input[type="password"],
  .prose input[type="url"],
  .prose input[type="number"],
  .prose input[type="tel"],
  .prose input[type="search"],
  .prose input[type="time"],
  .prose input[type="date"],
  .prose input[type="datetime-local"],
  .prose input[type="week"],
  .prose input[type="month"],
  .prose input[type="file"],
  .prose input[type="hidden"],
  .prose input[type="image"],
  .prose input[type="range"],
  .prose input[type="button"],
  .prose input[type="reset"],
  .prose input[type="submit"],
  .prose select { @apply input-bordered input input-secondary; }
  .prose textarea { @apply textarea-bordered textarea textarea-secondary !important; }
  .prose input:not(.radio), select { @apply focus:border-black focus:ring-offset-1 focus:ring-2 focus:ring-secondary-200 caret-info !important; }
  .prose input[type="color"] {@apply  rounded-sm w-40 h-10; }




  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="url"],
  input[type="number"],
  input[type="tel"],
  input[type="search"],
  input[type="time"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="week"],
  input[type="month"],
  input[type="file"],
  input[type="hidden"],
  input[type="image"],
  input[type="range"],
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {  }
  input.inline-field {@apply
  input-xs
  ;}
  input:not(.inline-field, .toggle, .checkbox, input[type="checkbox"], input[type="radio"]) {@apply
  w-full
  ;}



  .extras {
    @apply
    textarea
    textarea-bordered
    textarea-ghost
    textarea-primary
    textarea-secondary
    textarea-accent
    textarea-info
    textarea-success
    textarea-warning
    textarea-error
    input
    input-bordered
    input-ghost
    input-primary
    input-secondary
    input-accent
    input-info
    input-success
    input-warning
    input-error
    ;}
  body {
    @apply bg-base-100
  }
  #type-test { @apply space-y-1 ;}
  #type-test p { @apply flex flex-row space-x-4;}
  #type-test p i { @apply w-1/5 block;}
  #type-test p span { @apply w-4/5 block;}
  #type-test { @apply flex flex-row gap-x-4; }
  #type-test .col1 span {

    @apply knockout tracking-widest !important;
  }
  .typography-label {
    @apply text-xs p-1 bg-info-100 text-info-800 inline-block font-bold font-mono lowercase not-italic !important;
  }

  h1  {
    @apply
    w-full
    pt-4
    pb-3
    mb-4
    font-bold
    relative
    pr-14
    text-xl
    border-b
    border-base-300
    text-primary
    cursor-pointer
    ;
  }

  h2  {
    @apply
    w-full
    text-2xl
    pt-8
    pb-3
    ;
  }
  h3, .label-text-h3 {
    @apply
    w-full
    text-base
    font-bold
    ;
  }
  table thead th:first-child,
  table thead th:last-child,
  .table-zebra tbody tr td:first-child, .table-zebra tbody tr th:first-child,
  .table-zebra tbody tr td:last-child, .table-zebra tbody tr th:last-child {
    @apply rounded-none !important;
  }
  .bg-lightBlue {
    background-color: #5ABDC7;
  }

  .btn {
    font-size: 0.825rem;
    @apply tracking-wider;
  }
}
* {
  font-family: proxima-nova, ProximaNova-Regular, sans-serif;
}

.btn {
  font-size: 0.825rem;
  border-width: 2px;
}

.bg-info {
  background-color: #ffd000;
}
.bg-error {
  background-color: #ff1b1c;
}
.bg-success {
  background-color: #008000;
}
.bg-warning {
  @apply bg-warning-500;
}


.content-container {
  @apply mx-auto !important;
  @apply xl:container !important;
  @apply xl:mx-auto !important;
  @apply px-4;
  @apply w-full !important;
  @apply max-w-6xl !important;

}

.header-container {
  @apply max-w-full !important;
  @apply md:px-40 !important;
}

.textarea {
  @apply py-1;
}

.textarea-flexible {
  @apply min-h-0;
}

label.label {
  @apply
  align-top
  justify-start;
}
label.label input.checkbox {
  @apply
  w-5
  h-5
  rounded
  flex-shrink-0
  self-start
  mt-1;
}
label.label .label-text {
  @apply
  text-base
  flex-shrink;
}

.inline-input {
  @apply
  inline
  w-20
  ;
}

.select-neutral {
  @apply text-neutral;
}

.select-secondary {
  @apply bg-secondary !important;
  @apply text-secondary-content !important;
}

.select-secondary-alt {
  @apply text-secondary !important;
  @apply border-secondary;
  @apply focus:outline-none focus:ring focus:border-secondary-300;
}

.stacker .inline-form-element {
  @apply block;
}
.type-text, .type-date, .type-textarea, .type-dropdown, .type-checkboxes, .type-radiobuttons, .check {
  @apply w-full;
}
.dropdown-content > li {
  @apply flex;
}

.dropdown-secondary > div {
  @apply bg-secondary !important;
  @apply text-secondary-content !important;
  @apply hover:bg-secondary-focus !important;
}

.dropdown-secondary-alt > div {
  @apply text-secondary !important;
}

.fourCols-w-dividers {
  @apply my-0;
  @apply text-base;
  @apply flow-root;
  @apply flex-wrap;
  @apply md:justify-between;
  @apply md:flex;
  @apply lg:flex-nowrap ;
}

.fourCols-col1 {
  @apply block;
  @apply text-left;
  @apply pt-4;
  @apply pb-4;
  @apply items-start;
  @apply w-full;
  @apply md:flex-col;
  @apply md:pb-0;
  @apply md:pr-4;
  @apply md:pl-0;
  @apply md:py-4;
  @apply lg:w-1/4;
  @apply flex-shrink-0;
  @apply flex-grow-0;
}
.fourCols-col2 {
  @apply block ;
  @apply items-start;
  @apply pb-4;
  @apply pt-4;
  @apply md:pb-0;
  @apply md:flex-col;
  @apply md:pr-2;
  @apply md:pl-4;
  @apply lg:border-l;
  @apply lg:border-primary-content;
  @apply lg:border-opacity-50;
  @apply lg:w-1/4;
  @apply flex-shrink-0;
  @apply flex-grow-0;
}
.fourCols-col3 {
  @apply block;
  @apply items-start;
  @apply pb-4;
  @apply pt-4;
  @apply md:pb-0;
  @apply md:border-l;
  @apply md:border-primary-content;
  @apply md:border-opacity-50;
  @apply md:flex-col;
  @apply md:pr-2;
  @apply md:pl-4;
  @apply lg:w-1/4;
  @apply flex-shrink-0;
  @apply flex-grow-0;
}
.fourCols-col4 {
  @apply block;
  @apply items-start;
  @apply pt-4;
  @apply md:border-l;
  @apply md:border-primary-content;
  @apply md:border-opacity-50;
  @apply md:flex-col;
  @apply md:pr-2;
  @apply md:pl-4;
  @apply lg:w-1/4;
  @apply flex-shrink-0;
  @apply flex-grow-0;
}

.fourCols-equal .fourCols-col1, .fourCols-equal .fourCols-col2, .fourCols-equal .fourCols-col3, .fourCols-equal .fourCols-col4 {
  @apply md:w-1/4;
}

.promoModule .fourCols-col1, .promoModule .fourCols-col2, .promoModule .fourCols-col3, .promoModule .fourCols-col4 {
  @apply border-base-300;
}
.create-ops .fourCols-col1 {
  @apply md:pr-6;
  @apply lg:pr-12;
  @apply md:pb-0;
  @apply flex-shrink-0;
  @apply flex-grow-0;
}
.create-ops .fourCols-col2 {
  @apply md:pl-2;
  @apply md:pr-6;
  @apply md:border-l;
  @apply md:border-primary-content;
  @apply md:border-opacity-50;
  @apply lg:pl-3;
  @apply lg:pr-12;
}
.create-ops .fourCols-col3 {
  @apply md:pr-6;
  @apply lg:pr-12;
  @apply md:pl-2;
  @apply lg:pl-3;
  @apply md:py-0;
}
.create-ops .fourCols-col4 {
  @apply md:pl-6;
  @apply md:border-0;
}
:root {
  @apply bg-base-100;
}
.h6-label {
  @apply uppercase font-bold text-xs mb-2 mx-2 md:mx-0 lg:mb-4 tracking-wider;
}
.h6-label-sm {
  @apply text-sm mb-0 md:mb-4;
}
.h6-label-xs {
  @apply text-xs mb-0 md:mb-4;
}
.dd-menu {
  @apply btn !important;
  @apply btn-sm !important;
  @apply bg-white !important;
  @apply hover:bg-base-200 !important;
  @apply text-neutral !important;
  @apply justify-start text-left !important;
  @apply font-bold !important;
  @apply px-4  !important;
  @apply h-11  !important;
  @apply pr-24 !important;
  @apply rounded !important;
}
.dd-menu-item {
  @apply text-neutral !important;
  @apply w-full !important;
  @apply hover:text-primary-content !important;
  @apply hover:bg-primary !important;
}
.main-buttons {
  @apply bg-base-100;
  @apply text-primary;
  @apply text-base;
  @apply rounded-full;
  @apply px-8;
  @apply hover:bg-base-200 !important;
  @apply hover:text-primary;
}
.main-buttons.btn-primary {
  @apply bg-primary;
  @apply text-primary-content;
  @apply hover:bg-primary-focus !important;
  @apply hover:text-primary-content;
}
.btn-outline-secondary {
  @apply bg-accent-content;
  @apply text-secondary;
  @apply border-secondary;
  @apply hover:bg-secondary !important;
  @apply hover:text-accent-content;
}
.btn-tertiary {
  @apply bg-accent-content;
  @apply text-primary;
  @apply hover:bg-primary !important;
  @apply hover:text-primary-content;
}
.toggle-xs {
  --handleoffset: 1rem;
  @apply w-4;
  @apply w-8;
  @apply bg-base-100 !important;
  @apply checked:bg-primary !important;
  box-shadow: calc(var(--handleoffset)*-1) 0 0 2px var(--neutral) inset,0 0 0 2px var(--neutral) inset,var(--focus-shadow);
}
.toggle-xs:checked {
  @apply bg-base-100 !important;
  box-shadow: calc(var(--handleoffset)*+1) 0 0 2px var(--primary) inset,0 0 0 2px var(--primary) inset,var(--focus-shadow);
}


.wc-tabs {
  @apply tabs;
  @apply mb-4;
}
.wc-tab {
  @apply tab;
  @apply tab-lg;
  @apply tab-lifted !important;
}
.tab-active {
  @apply text-neutral !important;
  box-shadow: 0 -2px 10px #00000045;
}
.menu li>a {
  @apply text-primary !important;
  @apply px-2;
  @apply py-3;
  @apply md:px-5;
  @apply md:py-2;
}
.btn-on-primary {
  @apply
  bg-primary-content
  text-primary
  hover:text-primary-content
  hover:border-white
  ;
}
.btn-w-min {
  min-width: 200px;
}
.indent>div {
  @apply pl-10;
}
.banner-primary {
  @apply bg-primary text-white py-2 pb-6 pr-4;
  background-image: url(images/bg_pattern_primary.png);
  background-size: inherit;
  /* background-size: 100% auto; */
  @apply md:bg-cover;
}
.banner-base {
  background-image: url(images/bg_pattern_base.png);
  background-size: 100% auto;
}
.hero-background {
  background-image: url(images/login-hero.png);
  background-size: 100% auto;
  min-height: 23rem;
  @apply py-8;
}
.login-box {
  @apply max-w-md md:px-8 p-16 bg-neutral-content mx-auto py-8 rounded-box shadow-md;
}
.navLink {
  @apply block text-gray-600 lg:inline-block lg:mt-0 hover:text-gray-700 bg-gray-50 font-bold p-1 !important;
}
.table td:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 10;
}

table.table-striped {

}

table.table-striped th {
  @apply bg-base-100;
}

table.table-striped .row-striped td {
  @apply bg-base-200;
}

table.table-striped tr.row-nested_promo {
  @apply border-b border-primary-500 !important;
}
table.table-striped tr:not(.row-nested_promo) + .row-nested_promo {
  @apply border-t border-midgray-500 !important;
}

table.table-striped-auto tr:nth-of-type(odd) td {
  @apply bg-base-200;
}

.arrow-toggle.hidden {
  display: none !important;
}

.arrow-up {
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-180deg) !important;
}

.license-table td {
  padding: 0.125rem 0;
  border: none;
}
.btn-tabs>.btn-active {
  @apply bg-secondary;
  @apply hover:bg-secondary;
  @apply hover:text-primary-content;
}
.rounded-indicator {
  @apply flex;
  @apply w-10;
  @apply h-10;
  @apply rounded-full;
  @apply items-center;
  @apply justify-center;
  @apply bg-base-200;
  @apply text-primary;
  @apply mr-3;
  font-size: 1.675rem;
  flex: 0 0 2.5rem;
  padding-left: 3px;
}

.content-container > :not([hidden]) ~ .mt-0-i {
  margin-top: 0 !important;
}

.ml-0-i {
  margin-left: 0 !important;
}
.ml-4-i {
  margin-left: 1rem !important;
}

.menu li>a:active {
  @apply active:text-white !important;
}
.z-40 {
  z-index: 40;
}
.z-30 {
  z-index: 30;
}
.z-20 {
  z-index: 20;
}

label.label input.checkbox-sm {
  width: 1rem;
  height: 1rem;
}


/* Tab */
.tabs-space-bottom {
  padding-bottom: 5rem;
}

.tabs-space-bottom  + .content-container .tabs {
  margin-top: -4rem;
}

.tab-lifted:not(.tab-active) {
  box-shadow: 0 -2px 10px #00000045;
  @apply bg-base-200;
  @apply text-base-content;
}

.tab-lifted.tab-active {
  @apply text-secondary !important;
}
/* Tab */
samp {
  @apply font-mono px-1 inline-block border font-semibold border-success-200 bg-success-100 text-success-700 text-sm rounded-sm;
}
mark {
  @apply bg-warning-200 text-warning-900;
}
::marker {
  display: none;
}
pre {
  @apply text-2xs;
}
@layer components {
  .rounded {
    @apply rounded-sm;
  }
}
html#admi, html#admi body {
  @apply bg-base-100 text-base-content !important;
}
#admi .line-count {
  @apply font-mono text-base-content text-2xs;
}
#admi .w-4\/12  {
  /* @apply w-4/12 border-primary-500; */
  @apply border-primary-500;
}
#admi .w-8\/12 {
  /* @apply w-8/12; */
}
#admi textarea, #admi input {
  @apply
  bg-base-100
  border-base-content
  border-opacity-100
  border
  rounded-none
  h-10
  p-2
  !important;
}
#admi textarea {
  @apply
  focus:h-40 w-full textarea textarea-bordered !important;
}

.safelist {
  @apply md:w-1/2;
}
body .input-error {
  /* @apply input input-bordered input-error border-error-500 !important; */
  @apply input-error !important;
}
body .textarea-error {
  /* @apply textarea textarea-bordered textarea-error border-error-500 !important; */
  @apply textarea textarea-bordered border-error-500 !important;
}

label.label.checkbox-theme-error .label-text {
  @apply text-error;
}
label.label.checkbox-theme-error input.checkbox
{
  @apply border-error !important;
}
label.label.checkbox-theme-error input.checkbox:checked,
label.label.checkbox-theme-error input.checkbox-secondary:checked
{
  background-image: linear-gradient(
   -45deg,transparent 65%,hsl(0,100%,55.3%) 0),linear-gradient(
   45deg,transparent 75%,hsl(0,100%,55.3%) 0),linear-gradient(
   -45deg,hsl(0,100%,55.3%) 40%,transparent 0),linear-gradient(
   45deg,hsl(0,100%,55.3%) 30%,hsl(0,0%,100%) 0,hsl(0,0%,100%) 40%,transparent 0),linear-gradient(
   -45deg,hsl(0,0%,100%) 50%,hsl(0,100%,55.3%) 0);
}
/* #admi textarea.admin-inline-textInput {
    @apply
        inline-block
        input
        input-ghost
        input-bordered
        border-transparent
        input-sm
        ring-0
        font-mono
        font-semibold
        rounded-none
        w-full
        text-base-content
        focus:h-40
        resize
        placeholder-base-content placeholder-opacity-40

    ;
} */
#admi textarea.col-error  {
  @apply bg-primary-100 !important;
}
@import 'components/Layout/calendar.css';

.markdown-email.prose .cta {
  color: #0b4f6c;
  font-family: proxima-nova;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 0px;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
}
.markdown-email.prose h1, .h1-hero-title {
  font-family: Helvetica-Bold;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 36px;
  text-transform: uppercase;
  @apply  border-0 mb-0 pb-0 text-primary;
}
.markdown-email.prose h2, .h2-big-title {
  color: #a32a29;
  font-family: Knockout;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 1.8823529px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
}
.markdown-email.prose h3, .h3-medium-title {
  color: #666666;
  font-family: Knockout;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
}
.markdown-email.prose h4, .h4-subtitle {
  color: #666666;
  font-family: proxima-nova;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: none;
  vertical-align: top;
}
.markdown-email.prose h6, .h6-labels {
  color: #666666;
  font-family: proxima-nova;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
}
.markdown-email.prose h6, .h6 {
  color: #666666;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
}
.markdown-email.prose a:hover {
  @apply text-primary font-bold !important;
}
.markdown-email.prose a {
  @apply text-primary font-bold !important;
}
.markdown-email.prose .large-subtitle {
  color: #a32b29;
  font-family: proxima-nova;
  font-size: 2rem;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: none;
  vertical-align: top;
}
.markdown-email.prose .nav {
  color: #3d3d3d;
  font-family: Knockout;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 0px;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
}
.markdown-email.prose p, .markdown-email.prose div,  .markdown-email.prose .p-1-body-copy {
  font-family: Helvetica;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  @apply text-base-content;
}
.markdown-email.prose .p-2-fine-print {
  color: #3d3d3d;
  font-family: proxima-nova;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: none;
  vertical-align: top;
}
.markdown-email.prose .p-3-large-body-copy {
  color: #3d3d3d;
  font-family: proxima-nova;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0px;
  margin-top: 0;
  margin-bottom: 16px;
  opacity: 1;
  text-align: left;
  text-transform: none;
  vertical-align: top;
}
.email-btn-primary, .email-btn-secondary, .email-btn-outline {
  @apply
  btn-primary
  py-3
  px-8
  rounded-full
  btn-wide
  mx-auto
  font-sans
  text-base
  duration-100
  font-bold
  block
  text-center
  text-primary-content
  hover:bg-primary-focus
  ;
}
.email-btn-secondary {
  @apply bg-secondary !important;
  @apply hover:bg-secondary-focus !important;
  @apply text-secondary-content !important;
}
.email-btn-outline {
  @apply btn-outline !important;
  @apply border-primary !important;
  @apply text-primary !important;
  @apply hover:border-primary-focus !important;
  @apply hover:text-primary-focus !important;
  @apply border !important;
}
.edit-cover-link {
  @apply
  absolute
  top-0
  bottom-0
  left-0
  right-0
  block
  border
  border-l-2
  border-transparent
  rounded-lg
  hover:border-secondary-200;
}
.more {
  @apply
  md:w-1/5
  ;
}
body #root {
  --rounded-btn: 3px;
  --accent: #ffa800;
  --accent-4: #ffffff;
  --accent-focus: #de7b0f;
  --base-100: #fbfbfb;
  --base-200: #efefef;
  --base-300: #cccccc;
  --base-content: #3d3d3d;
  --error: #ff1b1c;
  --info: #1976d2;
  --neutral: #666666;
  --neutral-content: #ffffff;
  --neutral-focus: #3d3d3d;
  --primary: #a32b29;
  --primary-content: #ffffff;
  --primary-focus: #74121d;
  --secondary: #0b4f6c;
  --secondary-content: #ffffff;
  --secondary-focus: #07364a;
  --success: #008000;
  --warning: #ffd000;
}


/* Disable editing */
.edit-me {
  display: none;
}

/* Gatsby-generated styles ported over */
.gatsby-image-wrapper {
  position: relative;
  overflow: hidden;
}

.gatsby-image-wrapper img {
  height: 100%;
  margin: 0;
  max-width: none;
  padding: 0;
  width: 100%;
  object-fit: cover;
}

.gatsby-image-wrapper-constrained {
  display: inline-block;
  vertical-align: top;
}
/* END of Gatsby-generated styles ported over */

.modal {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

.Toastify__toast-body {
  white-space: pre-line;
}


#Description.relative.w-full textarea {
  @apply mb-8;
}

.not-first-row .budget-header{
  @apply
    sm:hidden;

  ;
}

.right-alignment {
  margin-left: auto;
  margin-right: 0;
}

/* Custom Lists */
/*
          Implementation Notes
  '::before' will be utilized
  '::marker' uses a separate counting mechanism in normal operated lists
*/

.ol-upper-latin {
  list-style-position: outside;
  --list-counter-style: upper-latin;
}

.ol-upper-latin::marker {
  content: none;
}

.ol-upper-latin li::marker {
  content: none;
}

.ol-upper-latin ol {
  --list-counter-style: lower-latin;
}

.approval-inactive {
  @apply bg-secondary-100/20;
}

.approval-active {
  @apply bg-primary-500;
}

.approval-checkbox-content p {
  @apply text-sm;
  @apply text-primary-500;
  @apply mb-0 !important;
}

.rounded-md {
  border-radius: 0.375rem;
}

.approval-radio {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  border-color: #6b7280;
  border-width: 1px;
  color: #2563eb;
  color-adjust: exact;
  display: inline-block;
  flex-shrink: 0;
  height: 1rem;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 1rem;
  border-radius: 100%
}

.approval-radio:checked {
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E")
}

.approval-radio:checked:focus, .approval-radio:checked:hover {
  background-color: currentColor;
  border-color: transparent
}
